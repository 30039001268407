import React, { Component } from 'react';
import TextLoop from "react-text-loop";
import Fade from 'react-reveal/Fade';
import posed from 'react-pose';
import Gallery from 'react-photo-gallery';

const leftBlock = {
    position: "relative",
    height: "100%",
    top: 0,
    //backgroundColor: "blue",
    left: 40,
    display: "table-cell",
}

const rightBlock = {
    position: "relative",
    minHeight: "100%",
    width: "100%",
    //float: "right",
    //backgroundColor: "green",
    display: "table-cell"
}

const LeftBox = posed.div({
    visible: {
        opacity: 1,
        transition: {
            duration: 1000,
            ease: 'easeIn',
        }
    },
    hidden: {
        opacity: 0,
        transition: {
            duration: 300,
            ease: 'easeOut',
        }
    },
});

const images =
    [{
        id: 0,
        src: "/media/pics/1.jpg",
        width: 4,
        height: 3
    },
    {
        id: 1,
        src: "/media/pics/2.jpg",
        width: 4,
        height: 3
    },
    {
        id: 2,
        src: "/media/pics/3.jpg",
        width: 4,
        height: 3
    },
    {
        id: 3,
        src: "/media/pics/4.jpg",
        width: 4,
        height: 3
    },
    {
        id: 4,
        src: "/media/pics/5.jpg",
        width: 1,
        height: 1
    },
    {
        id: 5,
        src: "/media/pics/6.jpg",
        width: 4,
        height: 3
    },
    {
        id: 6,
        src: "/media/pics/7.jpg",
        width: 2,
        height: 2
    },
    {
        id: 7,
        src: "/media/pics/8.jpg",
        width: 4,
        height: 3
    },
    {
        id: 8,
        src: "/media/pics/9.jpg",
        width: 16,
        height: 9
    },
    {
        id: 9,
        src: "/media/pics/10.jpg",
        width: 9,
        height: 16
    },
    {
        id: 10,
        src: "/media/pics/11.jpg",
        width: 4,
        height: 3
    },
    {
        id: 11,
        src: "/media/pics/12.jpg",
        width: 3,
        height: 2
    },
    {
        id: 12,
        src: "/media/pics/13.jpg",
        width: 3,
        height: 2
    },
    {
        id: 13,
        src: "/media/pics/14.jpg",
        width: 3,
        height: 2
    },
    {
        id: 14,
        src: "/media/pics/15.jpg",
        width: 3,
        height: 2
    },
    {
        id: 15,
        src: "/media/pics/16.jpg",
        width: 3,
        height: 2
    },
    {
        id: 16,
        src: "/media/pics/17.jpg",
        width: 4,
        height: 3
    },
    {
        id: 17,
        src: "/media/pics/18.jpg",
        width: 2,
        height: 3
    },
    {
        id: 18,
        src: "/media/pics/19.jpg",
        width: 3,
        height: 2
    },
    {
        id: 19,
        src: "/media/pics/20.jpg",
        width: 2,
        height: 3
    },
    {
        id: 20,
        src: "/media/pics/21.jpg",
        width: 16,
        height: 9
    },
    {
        id: 21,
        src: "/media/pics/22.jpg",
        width: 2,
        height: 2
    },
    {
        id: 22,
        src: "/media/pics/23.jpg",
        width: 16,
        height: 9
    },
    {
        id: 23,
        src: "/media/pics/24.jpg",
        width: 4,
        height: 3
    },
    {
        id: 24,
        src: "/media/pics/25.jpg",
        width: 4,
        height: 3
    },
    {
        id: 25,
        src: "/media/pics/26.jpg",
        width: 3,
        height: 2
    },
    {
        id: 26,
        src: "/media/pics/27.jpg",
        width: 9,
        height: 16
    },
    {
        id: 27,
        src: "/media/pics/28.jpg",
        width: 3,
        height: 2
    },
    {
        id: 28,
        src: "/media/pics/29.jpg",
        width: 4,
        height: 3
    },
    {
        id: 29,
        src: "/media/pics/30.jpg",
        width: 4,
        height: 3
    },
    {
        id: 30,
        src: "/media/pics/31.jpg",
        width: 3,
        height: 2
    },
    {
        id: 31,
        src: "/media/pics/32.jpg",
        width: 3,
        height: 2
    },
    {
        id: 32,
        src: "/media/pics/33.jpg",
        width: 3,
        height: 2
    },
    {
        id: 33,
        src: "/media/pics/34.jpg",
        width: 16,
        height: 9
    },
    {
        id: 34,
        src: "/media/pics/35.jpg",
        width: 16,
        height: 9
    },
    {
        id: 35,
        src: "/media/pics/36.jpg",
        width: 9,
        height: 16
    },
    ]

class ThirdSectionMobile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
        }
    }

    changeVisibility = (isVisible) => {
        this.setState({ isVisible });
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.isVisible !== nextProps.isVisible) {
            this.setState({ isVisible: nextProps.isVisible });
        }
    }

    render() {
        return (
            <div style={{ width: "100%", minHeight: "100%" }}>
                <div style={leftBlock}>
                    {/* <div style={{ fontSize: "5em", marginLeft: 20, display: "table-cell", verticalAlign: "middle" }}>
                        <LeftBox pose={this.state.isVisible ? 'visible' : 'hidden'} >
                            p
                                <TextLoop>
                                <span>ereți</span>
                                <span>ardoseli</span>
                                <span>lafoane</span>
                            </TextLoop>
                        </LeftBox >

                    </div> */}
                </div>

                {/* <Fade bottom when={this.state.isVisible}> */}
                <div style={{ ...rightBlock }}>
                    <div style={{ marginTop: 100, marginBottom: 100, paddingLeft: 20, paddingRight: 20, height: "auto" }}>
                        <Gallery photos={images} />
                    </div>
                </div>
                {/* </Fade> */}

            </div >
        );
    }
}

export default ThirdSectionMobile;