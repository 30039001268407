import React, { Component } from 'react';
import posed from 'react-pose';


const leftBlock = {
    position: "absolute",
    //backgroundColor: "blue",
    left: 40,
    display: "table",
    height: "100%",
}

const rightBlock = {
    position: "absolute",
    height: "100%",
    width: "100%",
    //backgroundColor: "green",
    display: "table"
}

const LeftBox = posed.div({
    visible: {
        opacity: 1,
        transition: {
            duration: 1000,
            ease: 'easeIn',
        }
    },
    hidden: {
        opacity: 0,
        transition: {
            duration: 300,
            ease: 'easeOut',
        }
    },
});

const Item = posed.div({
    visible: {
        opacity: 1,
        transition: ({ i }) => ({
            delay: i * 100,
            duration: 1000,
            ease: 'easeIn'
        })
    },
    hidden: {
        opacity: 0,
        transition: {
            duration: 300,
            ease: 'easeOut',
        }
    },
})

const texts = [{
    id: 0,
    text: <h2>De 15 ani, oferim soluții complete de amenajări interioare pentru spații office.<br /><br /></h2>
},
{
    id: 1,
    text: <h2>Pentru noi, fiecare colț contează, iar fiecare finisaj trebuie să fie perfect.</h2>
},
{
    id: 2,
    text: <h2>Filosofia noastră este una de continuă îmbunătățire, creștere, și dorință de a da mai mult.<br /><br /></h2>
},
{
    id: 3,
    text: <h2>Din 2004 până acum, ne-am depășit fiecare țel pe care ni l-am setat.</h2>
},
{
    id: 4,
    text: <h2>Plănuim să continuăm la fel, cu dumneavoastră.</h2>
},
]

class SecondSectionMobile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
        }
    }

    changeVisibility = (isVisible) => {
        this.setState({ isVisible });
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.isVisible !== nextProps.isVisible) {
            this.setState({ isVisible: nextProps.isVisible });
        }
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%", color: "white" }}>
                {/* 
                <div style={leftBlock}>
                    <div style={{ fontSize: "5em", marginLeft: 20, display: "table-cell", verticalAlign: "top" }}>
                        <LeftBox pose={this.state.isVisible ? 'visible' : 'hidden'} >
                            <p>despre noi</p>
                        </LeftBox>
                    </div>
                </div> */}


                <div style={rightBlock}>
                    <div style={{ display: "table-cell", textAlign: 'center', verticalAlign: "middle", paddingRight: 20, paddingLeft: 20 }}>
                        {
                            texts.map(({ id, text }) => (
                                <Item pose={this.state.isVisible ? 'visible' : 'hidden'} i={id} >
                                    {text}
                                </Item>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default SecondSectionMobile;