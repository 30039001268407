import React, { Component } from 'react';
import posed from 'react-pose';

const leftBlock = {
    position: "absolute",
    //backgroundColor: "blue",
    left: 40,
    display: "table",
    height: "100%"
}

const rightBlock = {
    position: "absolute",
    height: "100%",
    width: "100%",
    //backgroundColor: "green",
    // marginRight: 50,
    display: "table"
}

const LeftBox = posed.div({
    visible: {
        opacity: 1,
        transition: {
            duration: 1000,
            ease: 'easeIn',
        }
    },
    hidden: {
        opacity: 0,
        transition: {
            duration: 300,
            ease: 'easeOut',
        }
    },
});

const Item = posed.div({
    visible: {
        opacity: 1,
        transition: ({ i }) => ({
            delay: i * 50,
            duration: 500,
            ease: 'easeIn'
        })
    },
    hidden: {
        opacity: 0,
        transition: {
            duration: 300,
            ease: 'easeOut',
        }
    },
})

const images =
    [{
        id: 0,
        src: "/media/clients/adidas.png",
    },
    {
        id: 1,
        src: "/media/clients/amazon.png",
    },
    {
        id: 2,
        src: "/media/clients/ba.png",
    }, {
        id: 3,
        src: "/media/clients/bcr.png",
    },
    {
        id: 4,
        src: "/media/clients/bmw.png",
    },
    {
        id: 5,
        src: "/media/clients/carrefour.png",
    },
    {
        id: 6,
        src: "/media/clients/deloitte.png",
    },
    {
        id: 7,
        src: "/media/clients/dhl.png",
    },
    {
        id: 8,
        src: "/media/clients/dupont.png",
    },
    {
        id: 9,
        src: "/media/clients/exxon.png",
    },
    {
        id: 10,
        src: "/media/clients/ge.png",
    }, {
        id: 11,
        src: "/media/clients/hilton.png",
    },
    {
        id: 12,
        src: "/media/clients/hp.png",
    },
    {
        id: 13,
        src: "/media/clients/ibm.png",
    },
    {
        id: 14,
        src: "/media/clients/ing.png",
    },
    {
        id: 15,
        src: "/media/clients/megaimage.png",
    },
    {
        id: 16,
        src: "/media/clients/mercedes.png",
    }, {
        id: 17,
        src: "/media/clients/oracle.png",
    },
    {
        id: 18,
        src: "/media/clients/orange.png",
    },
    {
        id: 19,
        src: "/media/clients/porsche.png",
    },
    {
        id: 20,
        src: "/media/clients/renault.png",
    },
    {
        id: 21,
        src: "/media/clients/schneider.png",
    },
    {
        id: 22,
        src: "/media/clients/siemens.png",
    }, {
        id: 23,
        src: "/media/clients/vodafone.png",
    },]




class FourthSectionMobile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
        }
    }

    changeVisibility = (isVisible) => {
        this.setState({ isVisible });
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.isVisible !== nextProps.isVisible) {
            this.setState({ isVisible: nextProps.isVisible });
        }
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%", color: "white" }}>
                {/* <div style={leftBlock}>
                    <div style={{ fontSize: "3em", marginLeft: 20, display: "table-cell", verticalAlign: "bottom" }}>
                        <LeftBox pose={this.state.isVisible ? 'visible' : 'hidden'} >
                            <p>clienții noștri</p>
                        </LeftBox>
                    </div>
                </div> */}

                <div style={rightBlock}>
                    <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                        <div style={{ width: "100%", clear: "both" }} >
                            {
                                images.map(({ id, src }) => (
                                    <Item pose={this.state.isVisible ? 'visible' : 'hidden'} i={id}>
                                        <div id={id} style={{ display: "inline-block", float: "left", width: window.innerWidth / 6, height: window.innerHeight / 10, margin: 10, marginRight: 15, marginLeft: 15 }}>
                                            <img alt="" src={src} style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />
                                        </div>
                                    </Item>
                                ))
                            }
                        </div>
                    </div>
                </div>

                {/* <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                        <div style={{ width: "100%", clear: "both" }} >
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>
                                <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />
                            </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                        </div>
                        <div style={{ width: "100%", clear: "both" }} >
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                        </div>
                        <div style={{ width: "100%", clear: "both" }} >
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                        </div>
                        <div style={{ width: "100%", clear: "both" }} >
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                            <div style={{ display: "inline-block", float: "left", width: window.innerWidth / 14, height: window.innerHeight / 10, margin: 10 }}>                                 <img src="/media/clients/IBM_logo.png" style={{ objectFit: "scale-down", width: "100%", height: "100%" }} />                             </div>
                        </div>
                        {/* {photoRows.map(({ }) => (
                            <div
                                key={key}
                                className={className}
                                style={headerMenu}
                            ><a href={linkTo}>{label}</a></h2>
                        ))
                        } */}
                {/* </div> */}
                {/* </div> */}
            </div >
        );
    }
}

export default FourthSectionMobile;