import React, { Component } from 'react';
import './App.css';
import ReactFullpage from '@fullpage/react-fullpage';
import FirstSection from './components/firstSection';
import SecondSection from './components/secondSection';
import ThirdSection from './components/thirdSection';
import FourthSection from './components/fourthSection';
import FifthSection from './components/fifthSection';
import Header from './components/header';
import PortfolioHeader from './components/portfolioText';
import PortfolioBanner from './components/portfolioBanner';
import PortfolioBannerBottom from './components/portfolioBannerBottom';
import FirstSectionMobile from './components/firstSectionMobile';
import SecondSectionMobile from './components/secondSectionMobile';
import ThirdSectionMobile from './components/thirdSectionMobile';
import FourthSectionMobile from './components/fourthSectionMobile';
import FifthSectionMobile from './components/fifthSectionMobile';
import HeaderMobile from './components/headerMobile';
import PortfolioBannerMobile from './components/portfolioBannerMobile';


const pluginWrapper = () => {
	require('fullpage.js/vendors/scrolloverflow');
};

class App extends Component {

	constructor() {
		super()
		this.state = {
			headerTextColor: "white",
			firstVisible: true,
			width: window.innerWidth
		}
	}


	changeHeaderColor = (origin, destination, direction) => {
		console.log(destination.index)
		if (destination.index % 2 === 1) {
			this.setState({
				headerTextColor: "white"
			})
		}
		else {
			this.setState({
				headerTextColor: "#222629"
			})
		}
	}

	componentWillMount() {
		window.addEventListener('resize', this.handleWindowSizeChange);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};

	render() {
		var isMobile = this.state.width < 700

		if (isMobile)
			return (
				<div style={{ overflowX: 'hidden' }}>
					<HeaderMobile textColor={this.state.headerTextColor} />
					<PortfolioBannerMobile isVisible={this.state.thirdVisible} />
					<ReactFullpage
						autoScrolling={false}
						scrollOverflow={true}
						afterLoad={(origin, destination, direction) => {

							this.changeHeaderColor(origin, destination, direction)
							switch (destination.index) {
								case 0:
									this.setState({ firstVisible: true })
									break
								case 1:
									this.setState({ secondVisible: true })
									break
								case 2:
									this.setState({ thirdVisible: true })
									break
								case 3:
									this.setState({ fourthVisible: true })
									break
								case 4:
									this.setState({ fifthVisible: true })
									break
								default:
							}
						}}

						onLeave={(origin, destination, direction) => {
							switch (origin.index) {
								case 0:
									this.setState({ firstVisible: false })
									break
								case 1:
									this.setState({ secondVisible: false })
									break
								case 2:
									this.setState({ thirdVisible: false })
									break
								case 3:
									this.setState({ fourthVisible: false })
									break
								case 4:
									this.setState({ fifthVisible: false })
									break
								default:
							}
						}}
						render={({ state, fullpageApi }) => {
							return (

								<ReactFullpage.Wrapper>
									<div className="section fp-noscroll" data-anchor="homepage" style={{ color: '#222629' }}>
										<FirstSectionMobile isVisible={(this.state.firstVisible)} />
									</div>
									<div className="section fp-noscroll" data-anchor="about" style={{ backgroundColor: "#222629" }}>
										<SecondSectionMobile isVisible={(this.state.secondVisible)} />
									</div>
									<div className="section fp-auto-height-responsive" data-anchor="portfolio" style={{ color: '#222629' }}>
										<ThirdSectionMobile isVisible={(this.state.thirdVisible)} />
									</div>
									<div className="section fp-auto-height-responsive" data-anchor="clients" style={{ backgroundColor: "#222629" }}>
										<FourthSectionMobile isVisible={(this.state.fourthVisible)} />
									</div>
									<div className="section fp-noscroll" data-anchor="contact" style={{ color: '#222629' }} >
										<FifthSectionMobile isVisible={(this.state.fifthVisible)} />
									</div>
								</ReactFullpage.Wrapper>

							);
						}}
					/>
				</div>
			)
		else
			return (
				<div>
					<Header textColor={this.state.headerTextColor} />
					<PortfolioHeader isVisible={this.state.thirdVisible} />
					<PortfolioBanner isVisible={this.state.thirdVisible} />
					<PortfolioBannerBottom isVisible={this.state.thirdVisible} />
					{/* <ParallaxProvider> */}
					<ReactFullpage
						pluginWrapper={pluginWrapper}
						scrollOverflow={true}
						afterLoad={(origin, destination, direction) => {

							this.changeHeaderColor(origin, destination, direction)
							switch (destination.index) {
								case 0:
									this.setState({ firstVisible: true })
									break
								case 1:
									this.setState({ secondVisible: true })
									break
								case 2:
									this.setState({ thirdVisible: true })
									break
								case 3:
									this.setState({ fourthVisible: true })
									break
								case 4:
									this.setState({ fifthVisible: true })
									break
								default:
							}
						}}

						onLeave={(origin, destination, direction) => {
							switch (origin.index) {
								case 0:
									this.setState({ firstVisible: false })
									break
								case 1:
									this.setState({ secondVisible: false })
									break
								case 2:
									this.setState({ thirdVisible: false })
									break
								case 3:
									this.setState({ fourthVisible: false })
									break
								case 4:
									this.setState({ fifthVisible: false })
									break
								default:
							}
						}}

						render={({ state, fullpageApi }) => {
							return (

								<ReactFullpage.Wrapper>
									<div className="section fp-noscroll" data-anchor="homepage" style={{ color: '#222629' }}>
										<FirstSection isVisible={(this.state.firstVisible)} />
									</div>
									<div className="section fp-noscroll" data-anchor="about" style={{ backgroundColor: "#222629" }}>
										<SecondSection isVisible={(this.state.secondVisible)} />
									</div>
									<div className="section fp-auto-height-responsive" data-anchor="portfolio" style={{ color: '#222629' }}>
										<ThirdSection isVisible={(this.state.thirdVisible)} />
									</div>
									<div className="section fp-noscroll" data-anchor="clients" style={{ backgroundColor: "#222629" }}>
										<FourthSection isVisible={(this.state.fourthVisible)} />
									</div>
									<div className="section fp-noscroll" data-anchor="contact" style={{ color: '#222629' }} >
										<FifthSection isVisible={(this.state.fifthVisible)} />
									</div>
								</ReactFullpage.Wrapper>

							);
						}}
					/>
					{/* </ParallaxProvider> */}
				</div>
			)
	}
}

export default App;
