import React, { Component } from 'react';
import Slide from 'react-reveal/Slide';

const headerMenu = {
    display: "inline-block",
    width: "auto",
    float: "right",
    marginRight: 40,
    fontWeight: 200
}

const menuItems = [
    { label: "contact", key: "contact", className: "menuItem", linkTo: "#contact" },
    { label: "clienți", key: "clients", className: "menuItem", linkTo: "#clients" },
    { label: "portofoliu", key: "portfolio", className: "menuItem", linkTo: "#portfolio" },
    { label: "despre noi", key: "about", className: "menuItem", linkTo: "#about" },
]

class Header extends Component {
    render() {
        return (

            <div id="header"
                style={{
                    height: 50,
                    width: "100%",
                    position: "fixed",
                    zIndex: 100,
                    backgroundColor: "transparent",
                    color: this.props.textColor,
                    WebkitTransition: "color 1s ease-in"
                }}>

                {/* <img src="/media/logo_big.png" /> */}
                <Slide bottom>
                    <h2 style={{ marginLeft: 40, float: "left", display: "inline-block" }}><a href="#homepage">PRO VILA</a></h2>

                    {
                        menuItems.map(({ label, key, className, linkTo }) => (
                            <h2
                                key={key}
                                className={className}
                                style={headerMenu}
                            ><a href={linkTo}>{label}</a></h2>
                        ))
                    }
                </Slide>
            </div >
        )
    }
}

export default Header;