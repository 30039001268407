import React, { Component } from 'react';
import TextLoop from "react-text-loop";
import posed from 'react-pose';

const LeftBox = posed.div({
    visible: {
        opacity: 1,
        transition: {
            duration: 1000,
            ease: 'easeIn',
        }
    },
    hidden: {
        opacity: 0,
        transition: {
            duration: 300,
            ease: 'easeOut',
        }
    },
});

class PortfolioHeader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
        }
    }


    changeVisibility = (isVisible) => {
        this.setState({ isVisible });
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.isVisible !== nextProps.isVisible) {
            this.setState({ isVisible: nextProps.isVisible });
        }
    }

    render() {
        return (

            <div id="portfolio-text"
                style={{
                    height: "100%",
                    width: "40%",
                    position: "fixed",
                    display: "table",
                    marginLeft: 0,
                    backgroundColor: "transparent",
                    //color: this.props.textColor,
                    WebkitTransition: "color 1s ease-in",
                    zIndex: 99
                }}>


                <div style={{ fontSize: "5em", display: "table-cell", verticalAlign: "middle" }}>
                    <LeftBox pose={this.state.isVisible ? 'visible' : 'hidden'} style={{
                        marginLeft: 40,
                    }}>
                        p
                                <TextLoop>
                            <span>ereți</span>
                            <span>ardoseli</span>
                            <span>lafoane</span>
                        </TextLoop>
                    </LeftBox >
                </div>
                <div id="bottom-fade"></div>
            </div >
        )
    }
}

export default PortfolioHeader;