import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import { Rectangle } from 'react-shapes';

const leftBlock = {
    position: "absolute",
    // backgroundColor: "blue",
    left: 20,
    display: "table",
    height: "100%"
}

const rightBlock = {
    position: "absolute",
    // backgroundColor: "green",
    right: 40,
    bottom: 200
}

const imageBlock = {
    position: "absolute",
    width: window.innerWidth / 2,
    height: window.innerHeight / 2,
    // backgroundColor: "red",
    left: 60,
    top: 100
}

class FirstSectionMobile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
        }
    }

    changeVisibility = (isVisible) => {
        this.setState({ isVisible });
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.isVisible !== nextProps.isVisible) {
            this.setState({ isVisible: nextProps.isVisible });
        }
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <Fade bottom when={this.state.isVisible}>
                    <div style={leftBlock}>
                        <p style={{ fontSize: "4em", marginLeft: 20, display: "table-cell", verticalAlign: "bottom" }}>amenajări<br />office</p>
                    </div>

                    <div style={rightBlock}>
                        <Rectangle width={window.innerWidth / 2} height={window.innerHeight / 3} fill={{ color: 'transparent' }} stroke={{ color: '#222629' }} strokeWidth={30} />
                    </div>

                    {/* <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure"> */}
                    <div style={imageBlock}>
                        <img alt="Birou" src="./media/Glass_Partition_Wall.jpg" style={{ objectFit: "cover", width: "100%", height: "100%", position: "absolute" }} />
                    </div>
                    {/* </Parallax> */}

                </Fade>
                {/* <div style={angledStyle}>
                    
                </div> */}
            </div >
        );
    }
}

export default FirstSectionMobile;

//TODO Add resize event listeners