import React, { Component } from 'react';
import Slide from 'react-reveal/Slide';

const headerMenu = {
    display: "inline-block",
    position: 'absolute',
    width: "auto",
    float: "right",
    right: 0,
    marginRight: 20,
    fontWeight: 200,
}

const menuItems = [
    // { label: "despre noi", key: "about", className: "menuItem", linkTo: "#about" },
    // { label: "portofoliu", key: "portfolio", className: "menuItem", linkTo: "#portfolio" },
    // { label: "clienți", key: "clients", className: "menuItem", linkTo: "#clients" },
    { label: "contact", key: "contact", className: "menuItem", linkTo: "#contact" },
]

class HeaderMobile extends Component {
    render() {
        return (

            <div id="header"
                style={{
                    height: 200,
                    width: "100%",
                    position: "fixed",
                    zIndex: 100,
                    backgroundColor: "transparent",
                    color: this.props.textColor,
                    WebkitTransition: "color 1s ease-in"
                }}>

                {/* <img src="/media/logo_big.png" /> */}
                <Slide bottom>
                    <h2 style={{ marginLeft: 20, float: "left", display: "inline-block" }}><a href="#homepage">PRO VILA</a></h2>

                    {
                        menuItems.map(({ label, key, className, linkTo }) => (
                            <div>
                                <h2
                                    key={key}
                                    className={className}
                                    style={headerMenu}
                                >
                                    <a href={linkTo}>{label}</a>
                                </h2>
                                <br />
                                <br />
                            </div>
                        ))
                    }
                </Slide>
            </div >
        )
    }
}

export default HeaderMobile;