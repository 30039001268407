import React, { Component } from 'react';
import posed from 'react-pose';

const leftBlock = {
    position: "absolute",
    //backgroundColor: "blue",
    left: 40,
    display: "table",
    height: "100%"
}

const rightBlock = {
    position: "absolute",
    height: "100%",
    width: "50%",
    //backgroundColor: "green",
    right: 0,
    top: 0,
    display: "table"
}

const LeftBox = posed.div({
    visible: {
        opacity: 1,
        transition: {
            duration: 1000,
            ease: 'easeIn',
        }
    },
    hidden: {
        opacity: 0,
        transition: {
            duration: 300,
            ease: 'easeOut',
        }
    },
});

const Item = posed.div({
    visible: {
        opacity: 1,
        transition: ({ i }) => ({
            delay: i * 100,
            duration: 1000,
            ease: 'easeIn'
        })
    },
    hidden: {
        opacity: 0,
        transition: {
            duration: 300,
            ease: 'easeOut',
        }
    },
})

const texts = [{
    id: 0,
    text: <h2>sunați-ne la</h2>
},
{
    id: 1,
    text: <h1><span style={{ fontWeight: 400 }}>+40 (744) 540 402</span><br /><br /></h1>
},
{
    id: 2,
    text: <h2>sau trimiteți-ne email:</h2>
},
{
    id: 3,
    text: <h1><a href="mailto:contact@provila.ro"><span style={{ fontWeight: 400 }}>contact@provila.ro</span></a></h1>
},
]

class FifthSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
        }
    }

    changeVisibility = (isVisible) => {
        this.setState({ isVisible });
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.isVisible !== nextProps.isVisible) {
            this.setState({ isVisible: nextProps.isVisible });
        }
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div style={leftBlock}>
                    <div style={{ fontSize: "5em", marginLeft: 20, display: "table-cell", verticalAlign: "middle" }}>
                        <LeftBox pose={this.state.isVisible ? 'visible' : 'hidden'} >
                            <p>contact</p>
                        </LeftBox>
                    </div>
                </div>

                <div style={rightBlock}>
                    <div style={{ display: "table-cell", verticalAlign: "middle", paddingRight: 100 }}>
                        {
                            texts.map(({ id, text }) => (
                                <Item pose={this.state.isVisible ? 'visible' : 'hidden'} i={id} >
                                    {text}
                                </Item>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default FifthSection;