import React, { Component } from 'react';
import TextLoop from "react-text-loop";
import posed from 'react-pose';

const LeftBox = posed.div({
    visible: {
        opacity: 1,
        transition: {
            duration: 1000,
            ease: 'easeIn',
        }
    },
    hidden: {
        opacity: 0,
        transition: {
            duration: 300,
            ease: 'easeOut',
        }
    },
});

class PortfolioBannerBottom extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
        }
    }


    changeVisibility = (isVisible) => {
        this.setState({ isVisible });
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.isVisible !== nextProps.isVisible) {
            this.setState({ isVisible: nextProps.isVisible });
        }
    }

    render() {
        return (

            <div id="portfolio-text"
                style={{
                    height: 60,
                    width: "99%",
                    position: "fixed",
                    display: "table",
                    marginLeft: 0,
                    bottom: 0,
                    backgroundColor: "transparent",
                    //color: this.props.textColor,
                    WebkitTransition: "color 1s ease-in",
                    zIndex: 99
                }}>

                <LeftBox pose={this.state.isVisible ? 'visible' : 'hidden'}
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '100%',
                        height: 60,
                        backgroundImage: 'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0.6), rgba(255,255,255,0))'
                    }}></LeftBox>
                <div style={{ fontSize: "5em", display: "table-cell", verticalAlign: "middle" }}>
                </div>
            </div >
        )
    }
}

export default PortfolioBannerBottom;